var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { render } from 'react-dom';
import { ReactiveBase, DataSearch, StateProvider } from '@appbaseio/reactivesearch';
import { useState } from 'react';
import '../../styles/search.scss';
var resultItem = function (data) {
    (data.data.length === 0) ? suggestionBoxHandler(false) : suggestionBoxHandler(true);
    var getURLpathname = function (url) {
        if (!url || url === '')
            return '';
        var u = new URL(url);
        return u.pathname;
    };
    return (_jsxs("div", __assign({ className: "auto-suggestion-resultcontainer hidden" }, { children: [data.data.map(function (item, key) { return _jsx("div", { children: _jsx("a", __assign({ href: getURLpathname(item.source.permalink) }, { children: item.label })) }, key); }), _jsx("a", __assign({ id: "autosuggmore", href: "/?s=".concat(data.value) }, { children: "View More" }))] })));
};
var suggestionBoxHandler = function (suggestions) {
    var nav_container = document.querySelector('.nav-container');
    var suggestion_box = document.querySelector('.auto-suggestion-resultcontainer');
    if (suggestions) {
        if (nav_container)
            nav_container.style.zIndex = '198';
        if (suggestion_box)
            suggestion_box.classList.remove('hidden');
        return;
    }
    if (nav_container)
        nav_container.style.zIndex = '199';
    if (suggestion_box)
        suggestion_box.classList.add('hidden');
};
var RSearch = function () {
    var _a = useState(''), searchText = _a[0], setsearchText = _a[1];
    return (_jsx(_Fragment, { children: _jsxs(ReactiveBase, __assign({ app: 'pancanorg-post-1', headers: { Authorization: "Basic cGFuY2FuOlBjYW5SZXZpZXcxNTAwIQ==" }, url: "https://search.pancan.org/", enableAppbase: false }, { children: [_jsx(StateProvider, { onChange: function (prevState, nextState) {
                        if (prevState.SearchSensor && nextState.SearchSensor && (prevState.SearchSensor.value !== nextState.SearchSensor.value)) {
                            window.location.href = "/?s=".concat(nextState.SearchSensor.value);
                            //Add Analitics here event here if needed
                        }
                    } }), _jsx(DataSearch, { componentId: "SearchSensor", dataField: ["post_title", "meta.search_keywords.value", "meta.level_2_search_keywords.value"], placeholder: "What can we help you find?", fuzziness: 'AUTO', showVoiceSearch: false, queryFormat: 'or', autosuggest: true, size: 5, onValueChange: function (value) { return value !== '' ? setsearchText(value) : setsearchText(''); }, innerClass: { input: 'search-ep-input' }, className: 'searchfieldep', renderNoSuggestion: function () { return suggestionBoxHandler(false); }, render: function (data) { return resultItem(data); }, debounce: 100, showClear: true })] })) }));
};
var element = document.getElementById('search-input-header');
render(_jsx(RSearch, {}), element);
